import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";


const PrimaryBackgroundContainer = tw(Container)`-mx-4 mt-12 px-4 bg-primary-900 text-gray-100`;
const HeadingContainer = tw.div``;
// const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-2`;
// const Heading = tw(SectionHeading)`text-center`;
const Paragraph = tw.p`mt-6 text-gray-300 text-left leading-relaxed max-w-5xl mx-auto`;
const Span = styled.span`
  ${tw`font-bold `} 
`;

export default ({
  subheading = "",
  heading = "",
  description = "",
  
}) => {
  
    return (
        <PrimaryBackgroundContainer>
          <ContentWithPaddingXl>
            <HeadingContainer>
              {/* <Subheading>{subheading}</Subheading> */}
              {/* <Heading>{heading}</Heading> */}
              
    
              {/* Centered paragraphs below the description */}
              <Paragraph>
            With over <Span>50 years</Span> of combined <Span>business ownership</Span> experience, we have lived and breathed business - from 
            <Span> strategy</Span> and <Span>finance</Span> to <Span>leadership</Span> and <Span>organizational structure</Span>. We understand the challenges businesses face 
            because we have been in your shoes. As your business grows, so does the need for a <Span>strong foundation</Span> and <Span>adaptable strategies</Span>. 
            You have built your business through hard work, intuition, and experience, and now we're here to help make it even stronger.
          </Paragraph>

          <Paragraph>
            Our approach begins with a comprehensive assessment that highlights areas where <Span>technology</Span>, 
            <Span> operational efficiency</Span>, and <Span>process optimization</Span> can elevate your business. Whether it's ensuring <Span>compliance</Span>, 
            enhancing <Span>productivity</Span>, reinforcing <Span>cybersecurity</Span>, or increasing <Span>visibility</Span>, we create a roadmap tailored for your goals. 
            This roadmap isn't just about technology; it's about making sure every part of your business is equipped to <Span>manage business effectively</Span>, 
            sustain growth, and navigate future risks.
          </Paragraph>

          <Paragraph>
            With our guidance, you won't need to worry about technical jargon. Our job is to make these solutions <Span>understandable </Span> 
            and <Span> practical</Span>, helping you <Span>integrate systems</Span>, unlock <Span>valuable insights</Span>, and focus on what you do best. 
            We keep it simple: ensuring your business runs <Span>securely</Span> and <Span>efficiently</Span>, giving you the confidence to grow 
            with <Span>purpose</Span> and <Span>resilience</Span>.
          </Paragraph>
            </HeadingContainer>
            </ContentWithPaddingXl>
        </PrimaryBackgroundContainer>
  );
};
