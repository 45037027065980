import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiChartLineUp, PiArrowClockwise, PiGear, PiEye } from "react-icons/pi";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const page1Icons = [
    {
      heading: "Real-Time Insights for Informed Decisions",
      icon: <PiArrowClockwise />, 
      // description: "Access up-to-the-minute data to make timely, well-informed decisions that drive your business forward.",
    },
    {
      heading: "Data-Driven Strategies for Growth",
      icon: <PiChartLineUp />, 
      // description: "Turn raw data into actionable strategies that align with your growth goals and operational needs.",
    },
    {
      heading: "Scalable Tools for Evolving Needs",
      icon: <PiGear />, 
      // description: "Implement tools that grow with your business, ensuring continued alignment with your changing objectives.",
    },
    {
      heading: "Enhanced Data Visibility",
      icon: <PiEye />, 
      // description: "Gain a deeper understanding of your business through clear, actionable data visualizations and reporting.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
    <title>Business Intelligence Solutions | LariatCo</title>
    <meta
      name="description"
      content="Transform your data into actionable insights with LariatCo's business intelligence solutions. Gain real-time visibility, drive growth, and make informed decisions with scalable tools tailored to your needs."
    />
    <meta
      name="keywords"
      content="business intelligence, data-driven strategies, real-time insights, data visualization, scalable tools, actionable data, growth strategies"
    />
    <meta name="author" content="LariatCo" />
    <meta property="og:title" content="Business Intelligence Solutions | LariatCo" />
    <meta
      property="og:description"
      content="Unlock the power of data with LariatCo's business intelligence services. Gain real-time insights, develop data-driven strategies, and drive growth with scalable, cutting-edge tools."
    />
    <meta property="og:image" content="https://www.lariatco.com/banner.png" />
    <meta property="og:url" content="https://www.lariatco.com/services/business-intelligence" />
    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Business Intelligence Solutions | LariatCo" />
    <meta
      name="twitter:description"
      content="Make data work for you with LariatCo's business intelligence services. Real-time insights, scalable tools, and actionable strategies for growth."
    />
    <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
  </Helmet>
      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>Business Intelligence</Subheading>}
  heading={
    <>
      <HighlightedText>Turning Data Into Action</HighlightedText>
    </>
  }
  description="Unlock the full potential of your data with advanced business intelligence solutions. From real-time insights to tools that adapt to your evolving needs, we empower businesses with the visibility and strategies to drive growth and efficiency."
  icons={page1Icons}
          />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
