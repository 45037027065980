// ** I have made edits to this page origina is stored **//
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { GiWeightLiftingUp } from "react-icons/gi";
import { GiMountainCave } from "react-icons/gi";
import { GiCheckMark } from "react-icons/gi";
import { GiInfinity } from "react-icons/gi";


const Container = tw.div`relative mb-12`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 pb-4 md:py-12 md:pb-4 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-3/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`w-full mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none justify-center md:justify-start gap-y-8 gap-x-8`;
const Feature = tw.div`flex flex-col items-center md:items-start w-full md:w-1/3 lg:w-1/4 text-center md:text-left`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-3 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  subheading = "",
  heading = (
    <>
      We Have a <span tw="text-primary-500">PARTNERSHIP</span> in Mind.
    </>
  ),
  description = "",
  primaryButtonText = "Discover More",
  primaryButtonUrl = "/aboutus",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
 
  const defaultFeatures = [
    {
      Icon: GiInfinity,
      title: "Long-term",
      description: "We offer on-going support to ensure that the implemented solutions continue to deliver value as your business evolves.",
      iconContainerCss: tw`bg-primary-300 text-white`,

    },
    {
      Icon: GiCheckMark,
      title: "Custom Design",
      description: "We design all our solutions to align specifically to our client's business needs, making each opportunity custom to the fullest.",
      iconContainerCss: tw`bg-primary-300 text-white`, 

    }, 
    {
      Icon: GiWeightLiftingUp,
      title: "Empowering You",
      description: "We are not designed to simply create & implement solutions but to empower our clients through comfortable transitions of control.",
      iconContainerCss: tw`bg-primary-300 text-white`,

    },
    {
      Icon: GiMountainCave,
      title: "Big Picture",
      description: "We take a broad view of the business, considering a comprehensive strategy that involves all functions and how they interact.",
      iconContainerCss: tw`bg-primary-300 text-white`, 

    }, 
    
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        {/* <ImageColumn>
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {showDecoratorBlob && <DecoratorBlob />}
        </ImageColumn> */}
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index} href={features.url}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                  </Feature>
              ))}
            </Features>

            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
