import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg"; // Example icon, replace as needed

const Container = tw.div`w-full md:w-[60%] max-w-screen-lg mx-auto pt-6`;
const Heading = tw.h2`text-3xl font-bold text-start`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const Description = tw.p`mt-4 ml-0 mx-5 text-left text-lg text-gray-600`;
const CardsContainer = tw.div`flex flex-col items-start`;
const Card = styled.div`
  ${tw`flex items-center px-1 py-1 mt-2 w-full`}
  .imageContainer {
    ${tw`rounded-full p-3 bg-gray-100 mr-4`}
    i {
      ${tw`w-8 h-8`} font-size: 1.5rem
    }

  }
  .title {
    ${tw`font-bold text-lg `} display: flex;
    align-items: center;
  }
  .description {
    ${tw`text-sm text-secondary-300`} 
  }
`;

const GetStartedLink = tw.a`flex items-center text-primary-500 text-lg font-semibold px-4 mt-8`;
const ArrowIcon = tw(ArrowRightIcon)`ml-2 w-4`;

const MainFeature1 = ({ heading, subheading, description, icons }) => {
  return (
    <Container>
      {subheading && <Subheading>{subheading}</Subheading>}
      {heading && <Heading>{heading}</Heading>}
      {description && <Description>{description}</Description>}
      <CardsContainer>
        {icons.map((icon, index) => (
          <Card key={index}>
            <span className="imageContainer">
            <i className="a" >{icon.icon}</i>
            </span>
            <span className="title">{icon.heading}</span>
            <p className="description">{icon.description || ""}</p>
          </Card>
        ))}
        <GetStartedLink href="/contact">
        Schedule a Consultation Today
        <ArrowIcon />
      </GetStartedLink>
      </CardsContainer>
      
    </Container>
  );
};

export default MainFeature1;
