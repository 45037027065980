import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiWrench, PiPlug, PiDatabase, PiCloudArrowUpLight } from "react-icons/pi";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const page1Icons = [
    {
      heading: "Unified Data Architecture",
      icon: <PiDatabase />,
      // description: "Establish a single, consistent data source for all your business needs.",
    },
    {
      heading: "Scalable Infrastructure",
      icon: <PiCloudArrowUpLight />,
      // description: "Implement systems that grow seamlessly with your expanding data requirements.",
    },
    {
      heading: "Rapid Data Integration",
      icon: <PiPlug />,
      // description: "Quickly add new data and sources to keep up with business dynamics.",
    },
    {
      heading: "Future-Proof Structuring",
      icon: <PiWrench />,
      // description: "Build a solid data foundation designed for long-term adaptability and success.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Data Infrastructure Services | LariatCo</title>
  <meta
    name="description"
    content="Transform your data landscape with LariatCo's scalable data infrastructure solutions. Establish a unified architecture, integrate rapidly, and build a future-proof foundation for long-term success."
  />
  <meta
    name="keywords"
    content="data infrastructure, scalable systems, unified architecture, data integration, future-proofing, data technology"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="Data Infrastructure Services | LariatCo" />
  <meta
    property="og:description"
    content="Build a solid data foundation with LariatCo. Scalable infrastructure, unified architecture, and rapid integration to meet your evolving business needs."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/services/data-infrastructure" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Data Infrastructure Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Transform your data infrastructure with scalable solutions by LariatCo. Unified architecture, rapid integration, and future-proofing for business growth."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/images/banner.png" />
</Helmet>

      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>Data Technology</Subheading>}
  heading={
    <>
      <HighlightedText>Building a Scalable Data Foundation</HighlightedText>
    </>
  }
  description="Transform your data landscape with a scalable infrastructure designed as a single source of truth. Our solutions ensure easily accessible, adaptable systems that are meticulously structured from ground up to remain agile and grow with your evolving needs."
  icons={page1Icons}
          />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
