import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/MainHeader.js";
import Footer from "components/footers/MainFooter";
import ContactUsForm from "components/forms/ContactPageForm";
// import Subscribe from "components/misc/Subscribe"
import ContactDetails from "components/cards/ContactPageDetails";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Contact Us | Get in Touch with LariatCo</title>
  <meta
    name="description"
    content="Have questions or need assistance? Contact LariatCo for expert solutions. Find our address, phone number, and email, or send us a message using our contact form."
  />
  <meta
    name="keywords"
    content="contact LariatCo, get in touch, business support, email us, call us, address, contact form, customer service"
  />
  <meta name="author" content="LariatCo" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta property="og:title" content="Contact Us | Get in Touch with LariatCo" />
  <meta
    property="og:description"
    content="Reach out to LariatCo for assistance with your business needs. Use our contact form, call us, or email us today!"
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/contact" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Contact Us | Get in Touch with LariatCo" />
  <meta
    name="twitter:description"
    content="Have questions? Contact LariatCo today via phone, email, or our contact form. We're here to help your business thrive!"
  />
  <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
</Helmet>
      <Header />
      <ContactUsForm />
      {/* <Subscribe /> */}
      <ContactDetails />
      <Footer />
    </AnimationRevealPage>
  );
};
