import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import Form from "components/forms/SolutionsQuestionaireForm"
import { Helmet } from "react-helmet";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;
const MainContainer = styled.div`
  padding-top: 10px; /* Minimal padding to the top of the container */
  margin-bottom: 0; /* No bottom margin to reduce spacing between SubHeader and Form */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center both SubHeader and Form */
  justify-content: center;
  width: 100%;
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Our Services | LariatCo</title>
  <meta
    name="description"
    content="Explore LariatCo's comprehensive services, including business intelligence, cybersecurity, system integration, data infrastructure, fractional leadership, and technology infrastructure. Empower your business to thrive."
  />
  <meta
    name="keywords"
    content="business services, business intelligence, cybersecurity, system integration, SaaS integration, data infrastructure, fractional leadership, technology infrastructure, scalable solutions"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="Our Services | LariatCo" />
  <meta
    property="og:description"
    content="Discover the full range of services offered by LariatCo. From business intelligence to cybersecurity, system integration, and beyond, we help businesses achieve growth and efficiency."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.jpg" />
  <meta property="og:url" content="https://www.lariatco.com/services" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Our Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Explore LariatCo's comprehensive services, including business intelligence, cybersecurity, system integration, data infrastructure, fractional leadership, and technology infrastructure."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/banner.jpg" />
</Helmet>

      <Header />
      <MainContainer>
      <SubHeader />
      <Form />
       </MainContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
