// This is edited to customize
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { BsClipboard2Check, BsGear, BsDatabaseExclamation, BsFingerprint, BsFloppy} from "react-icons/bs";
import { GrDocumentPerformance } from "react-icons/gr";
import { PrimaryButton } from "components/misc/Buttons";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`} 
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
    .icon {
      ${tw`ml-2 w-4`}
    }
`;


export default ({cards = undefined, heading = "How we Help", subheading = "", description = "These are some of the top challenges our clients face.  Allow us to come along side you in your journey."
}) => {
  
  const defaultCards = [
    {
        imageSrc: <BsClipboard2Check />,
        icon: <BsClipboard2Check />,
        title: "Compliance",
        description: "Struggling to meet complex regulatory standards while managing the time and resources they demand"
    },
    {
        imageSrc: <BsGear />,
        icon: <BsGear />,
        title: "Productivity",
        description: "Delays and inefficiencies caused by overwhelming tasks, missing SOPs, and redundant processes are hampering productivity"
    },
    {
        imageSrc: <GrDocumentPerformance />,
        icon: <GrDocumentPerformance />,
        title: "Reporting Access",
        description: "Lack of access to clear, data-driven insights makes informed decision-making difficult"
    },
    {
        imageSrc: <BsDatabaseExclamation />,
        icon: <BsDatabaseExclamation />,
        title: "Data Management",
        description: "Poor data management and integration are creating bottlenecks and workflow disruptions"
    },
    {
        imageSrc: <BsFingerprint />,
        icon: <BsFingerprint />,
        title: "Cybersecurity",
        description: "Vulnerabilities in your cybersecurity framework could expose critical systems to threats and risks"
    },
    {
        imageSrc: <BsFloppy />,
        icon: <BsFloppy />,
        title: "Legacy Systems",
        description: "Balancing the value of legacy systems with modernization challenges creates uncertainty and operational risks"
    },
];




  
  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
            <span className="icon" style={{ fontSize: "1.5rem" }}>
              {card.icon} {/* Render the icon directly */}
            </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        <PrimaryButton  as="a" href={`/services`}>
              Learn More
            </PrimaryButton>
      </ThreeColumnContainer>
    </Container>
  );
};
