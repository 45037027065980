import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-4 lg:my-4 bg-primary-900 text-gray-100 mx-4 px-4`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md `;

export default ({
  subheading = "",
  heading = "Our why",
  description = <>Leading a business can be taxing and can often feel lonely.  
  You are getting <span tw="text-orange-500"><wbr/><a href="#h.i.t."> H.I.T. </a></span>from all sides.  
  We do not believe it has to be that way.  Why we continue to support small to medium sized busineeses is because they are the cornerstone of the business environment.
  We keep pushing businesses to thrive by providing access to strategies, resources, tools, people, and so much more because we believe in you!</>,
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
