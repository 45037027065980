import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiListChecks, PiShieldWarningLight, PiRocketLaunch, PiMapPinArea } from "react-icons/pi";
import { Helmet } from "react-helmet";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const page1Icons = [
    {
      heading: "Comprehensive Technology Audits",
      icon: <PiListChecks />, 
      // description: "Evaluate your data systems, cybersecurity measures, and infrastructure to ensure alignment with best practices and future needs.",
    },
    {
      heading: "Identify Inefficiencies and Vulnerabilities",
      icon: <PiShieldWarningLight />,
      // description: "Pinpoint critical weaknesses and inefficiencies that could impact operations, security, and compliance.",
    },
    {
      heading: "Scalable Roadmaps for Modernization",
      icon: <PiRocketLaunch />, 
      // description: "Receive tailored roadmaps designed to modernize legacy systems, improve compliance, and scale your business effectively.",
    },
    {
      heading: "Actionable Insights and Budgets",
      icon: <PiMapPinArea />, 
      // description: "Gain clear outcomes, including prioritized recommendations, detailed budgets, and actionable implementation plans.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Helmet>
  <title>Business Assessment Services | LariatCo</title>
  <meta
    name="description"
    content="Unlock growth and efficiency with LariatCo's business assessment services. Comprehensive audits to address inefficiencies, vulnerabilities, and compliance while creating tailored modernization roadmaps."
  />
  <meta
    name="keywords"
    content="business assessment, technology audits, modernization, compliance, inefficiencies, vulnerabilities, scalable roadmaps, actionable insights"
  />
  <meta name="author" content="LariatCo" />
  <meta property="og:title" content="Business Assessment Services | LariatCo" />
  <meta
    property="og:description"
    content="Gain clarity and direction with LariatCo's business assessments. Pinpoint vulnerabilities, address inefficiencies, and modernize with tailored solutions."
  />
  <meta property="og:image" content="https://www.lariatco.com/banner.png" />
  <meta property="og:url" content="https://www.lariatco.com/services/business-assessment" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Business Assessment Services | LariatCo" />
  <meta
    name="twitter:description"
    content="Comprehensive business assessments by LariatCo. Identify inefficiencies, ensure compliance, and plan for scalable growth."
  />
  <meta name="twitter:image" content="https://www.lariatco.com/banner.png" />
</Helmet>

      <Header />
      <Container>
      <SubHeader />
        <TwoColumn>
        
        <MainFeature1
  subheading={<Subheading>Business Assessment</Subheading>}
  heading={
    <>
      <HighlightedText>Insights for Modernization and Growth</HighlightedText>
    </>
  }
  description="Gain clarity and direction with a comprehensive business assessment focused on data technology, cybersecurity, compliance, and legacy systems. Our in-depth audits pinpoint inefficiencies, address vulnerabilities, and provide tailored roadmaps for modernization and growth."
  icons={page1Icons}
          />
          
            <SimpleContactUs />
          
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
